<script>
    import { required, minLength, numeric } from "vuelidate/lib/validators";
    import General from '@/services/General';
    import Customers from '@/services/Customers';
    import Swal from "sweetalert2";
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        data() {
            return {
                firstname: '',
                lastname: '',
                gender: '',
                gender_options: [
                    { item: 'M', name: 'Male' },
                    { item: 'F', name: 'Female' },
                ],
                email: '',
                line1: '',
                line2: '',
                city: '',
                state_province: '',
                country: '',
                zip: '',
                address_type: '',
                phone_raw: '',
                phone_country: '',
                phone_type: 'mobile',
                phone_formatted: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                countries: {},
                prefix: '',
                splitedPhoneStr: '',
                phone_number: '',
                verified_checked: false,
                mailing_checked: false,
            };
        },
        validations: {
            firstname: {
                required
            },
            lastname: {
                required
            },
            email: {
                required
            },
            phone_number: {
                required,
                minLength: minLength(2),
                numeric
            },
        },
        created() {
            this.getCountry()
        },
        methods: {

            async addCustomer(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {

                        await Customers.addCustomer({
                            first_name: this.firstname,
                            last_name: this.lastname,
                            gender: this.gender,
                            phone_raw: this.phone_number,
                            phone_country: this.phone_country,
                            phone_type: this.phone_type,
                            email: this.email,
                            line1: this.line1,
                            line2: this.line2,
                            city: this.city,
                            state_province: this.state_province,
                            zip: this.zip,
                            country: this.country,
                            address_type: this.address_type,
                            verified: this.verified_checked == true ? 'Y' : 'N',
                            mailing: this.mailing_checked == true ? 'Y' : 'N'
                        }).then((response) => {
                            this.successmsg(response);
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                            this.phone_number = '';
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            phoneBase(){
                if(this.phone_country){
                    this.splitedPhoneStr = this.phone_formatted.split(" ");
                    this.splitedPhoneStr.shift();
                    this.phone_number = this.splitedPhoneStr.join('');
                }else{
                    this.phone_number = this.phone_raw;
                }
            },
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },
            async getCountry() {
                try {
                    await General.getCountry({

                    }).then((response) => {
                        this.countries = response.data;
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {
                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },
        }
    };
</script>

<template>
    <b-modal v-model="showModal" id="add_new_customer" title="Add new Customer" title-class="font-18" size="lg" centered>
        <form @submit.prevent="addCustomer">
            <div class="row">
                <div class="col">
                    <b-form-group label="First Name" label-for="formrow-firstname-input" class="mb-3">
                        <b-form-input
                                v-model.trim="firstname"
                                type="text"
                                id="firstname"
                                aria-describedby="firstname-feedback"
                                :class="{
                                  'is-invalid': submitted && $v.firstname.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'First Name'" :validationName="$v.firstname"></validationMessages>
                    </b-form-group>

                    <b-form-group label="Last Name" label-for="formrow-lastname-input" class="mb-3">
                        <b-form-input
                                v-model.trim="lastname"
                                type="text"
                                id="lastname"
                                aria-describedby="lastname-feedback"
                                :class="{
                                  'is-invalid': submitted && $v.lastname.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Last Name'" :validationName="$v.lastname"></validationMessages>
                    </b-form-group>

                    <b-form-group label="Gender" label-for="formrow-gender-input" class="mb-3">
                        <b-form-radio-group
                                v-model="gender"
                                :options="gender_options"
                                value-field="item"
                                text-field="name"
                                name="radio-inline"
                                inline
                        ></b-form-radio-group>
                    </b-form-group>

                    <hr>

                    <b-form-group label="Email" label-for="formrow-email-input" >
                        <b-form-input
                                v-model.trim="email"
                                type="text"
                                id="email"
                                aria-describedby="email-feedback"
                                :class="{
                                  'is-invalid': submitted && $v.email.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.email"></validationMessages>
                    </b-form-group>

                    <hr>

                    <label>Phone Number</label>
                    <b-input-group class="mb-3">
                        <select v-model="phone_country" class="form-select">
                            <option v-for="country in countries" :key="country.alpha2code" :value="country.alpha2code">{{country.alpha2code}} ({{country.calling_code}})</option>
                        </select>
                        <b-input-group-append>
                            <b-form-group label-for="formrow-phone-input" >
                                <b-form-input
                                        v-model.trim="phone_number"
                                        type="text"
                                        id="phone"
                                        aria-describedby="order-feedback"
                                        :class="{
                                            'is-invalid': submitted && $v.phone_number.$error,
                                        }"
                                >
                                </b-form-input>
                                <validationMessages class="position-absolute" v-if="submitted" :fieldName="'Phone'" :validationName="$v.phone_number"></validationMessages>
                            </b-form-group>
                        </b-input-group-append>
                    </b-input-group>

                    <b-form-group label="Phone Type" label-for="formrow-phone_type-input" class="mb-3">
                        <select v-model="phone_type" class="form-select">
                            <option value="mobile">Mobile</option>
                            <option value="fixed">Fixed</option>
                        </select>
                    </b-form-group>

                </div>

                <div class="col">
                    <b-form-group label="Street Name" label-for="formrow-streetname-input" class="mb-3">
                        <b-form-input
                                v-model.trim="line1"
                                type="text"
                                id="line1"
                                aria-describedby="line1-feedback"
                        >
                        </b-form-input>

                    </b-form-group>

                    <b-form-group label="House Number" label-for="formrow-housenumber-input" class="mb-3">
                        <b-form-input
                                v-model.trim="line2"
                                type="text"
                                id="line2"
                                aria-describedby="line2-feedback"
                        >
                        </b-form-input>

                    </b-form-group>

                    <b-form-group label="City" label-for="formrow-city-input" class="mb-3">
                        <b-form-input
                                v-model.trim="city"
                                type="text"
                                id="city"
                                aria-describedby="city_e-feedback"
                        >
                        </b-form-input>

                    </b-form-group>

                    <b-form-group label="State Province" label-for="formrow-stateprovince-input" class="mb-3">
                        <b-form-input
                                v-model.trim="state_province"
                                type="text"
                                id="state_province"
                                aria-describedby="stateprovince-feedback"
                        >
                        </b-form-input>
                    </b-form-group>

                    <b-form-group label="Zip" label-for="formrow-zip-input" class="mb-3">
                        <b-form-input
                                v-model.trim="zip"
                                type="text"
                                id="zip"
                                aria-describedby="zip-feedback"
                        >
                        </b-form-input>

                    </b-form-group>

                    <b-form-group label="Country" label-for="formrow-country-input" class="mb-3">
                        <b-form-input
                                v-model.trim="country"
                                type="text"
                                id="country"
                                aria-describedby="country-feedback"
                        >
                        </b-form-input>

                    </b-form-group>

                    <b-form-group label="Address Type" label-for="formrow-address_type-input" class="mb-3">
                        <select v-model="address_type" class="form-select">
                            <option value="unknown">Unknown</option>
                            <option value="home">Home</option>
                            <option value="business">Business</option>
                        </select>
                    </b-form-group>

                    <b-form-checkbox-group>
                        <div class="form-check form-switch form-switch-lg mb-3" >
                            <input v-model="verified_checked"
                                   type="checkbox"
                                   class="form-check-input"
                                   id="verifiedAddress"
                            />
                            <label class="form-check-label" for="verifiedAddress">Verified Address</label>
                        </div>
                    </b-form-checkbox-group>

                    <b-form-checkbox-group>
                        <div class="form-check form-switch form-switch-lg mb-3" >
                            <input v-model="mailing_checked"
                                   type="checkbox"
                                   class="form-check-input"
                                   id="mailingAddress"
                            />
                            <label class="form-check-label" for="mailingAddress">Mailing Address</label>
                        </div>
                    </b-form-checkbox-group>

                    <hr>
                </div>
            </div>

        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addCustomer" :disabled="tryingToEdit">Submit</b-button>
        </template>
    </b-modal>
</template>