<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Customers from '@/services/Customers';
import modalAddCustomer from  '@/components/modals/customers/modalAddCustomer';
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";


export default {
  components: { Layout, PageHeader, modalAddCustomer, DatePicker, Multiselect },
  page: {
      title: "Users",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
  },
  data() {
    return {
        error: null,
        tableData: [],
        title: "Customers",
        items: [
          {
            text: "CRM",
            href: "/customers",
          },
          {
            text: "Users",
            active: true,
          },
        ],
        genderOptions: [
          { text: 'Male', value: 'M' },
          { text: 'Female', value: 'F' },
          { text: 'None', value: '' },
        ],
        userStatuses: [],
        filterInput: {
          first_name: '',
          last_name: '',
          gender: [],
          email: '',
          birthdate: '',
          birthdates_temp: [],
          userStatus: [],
        },
        isBusy: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "age",
        sortDesc: false,
        fields: [
            {
              key: "first_name",
              label: "Customer",
              sortable: true,
            },
            {
              key: "email",
              label: "Email",
              sortable: true,
            },
            {
              key: "date_of_birth",
              label: "Birth Date",
              sortable: true,
            },
            {
              key: "gender",
              label: "Gender",
              sortable: true,
            },
            {
              key: "created_on",
              label: "Join Date",
              sortable: true,
            },
            {
              key: "status",
              label: "Status",
              sortable: true,
            },
        ],
        
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  async created() {
    this.getUserStatuses()
  },
  methods: {

      /**
     * Search the table data with search input
     */
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      async getCustomers(){
          try {
            this.toggleBusy();
            var filters = this.getFilters()
            const response = await Customers.getCustomers(filters)
            this.tableData = response.data.data
            this.toggleBusy();
          } catch (error) {
            this.toggleBusy();
            this.error = error.response.data.error ? error.response.data.error : "";
            this.tableData = []
          }
        },

        modalAddCustomer(){
          this.$bvModal.show("add_new_customer");
        },

        getFilters(){
          var filter_string = '';
          filter_string += (this.filterInput.first_name) ? '&first_name='+this.filterInput.first_name : "";
          filter_string += (this.filterInput.last_name) ? '&last_name='+this.filterInput.last_name : "";
          filter_string += (this.filterInput.gender.length) ? '&gender='+this.filterInput.gender : "";
          filter_string += (this.filterInput.email) ? '&email='+this.filterInput.email : "";

          if(this.filterInput.birthdate.length == 2){
            filter_string += (this.filterInput.birthdate[0]) ? '&birthdate_s='+moment(this.filterInput.birthdate[0]).format('YYYY-MM-DD') : "";
            filter_string += (this.filterInput.birthdate[1]) ? '&birthdate_e='+moment(this.filterInput.birthdate[1]).format('YYYY-MM-DD') : "";
          }

          filter_string += (this.filterInput.userStatus.length) ? '&user_status='+JSON.stringify(this.filterInput.userStatus) : "";

          return filter_string = filter_string.substring(1);
        },

        async getUserStatuses(){
          try {
            await Customers.getUserStatuses({

            }).then((response) => {
              var statuses = [];
              response.data.forEach(function (item) {
                if(item.status){
                  statuses.push(item.status);
                }
              });
              this.userStatuses = statuses;
            }).catch(error => {
              this.error = error.response.data.error ? error.response.data.error : "";
              this.failedmsg(this.error)
            }).finally(() => {
            })

          } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.failedmsg(this.error)
          }
        }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div class="row mt-4">
              <div class="col-md-12">
                <div class="mb-3">
                  <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="modalAddCustomer">
                    <i class="mdi mdi-plus me-2"></i>
                    Create New
                  </a>
                </div>
              </div>
              <div class="col-md-12">
                <div class="custom-accordion">
                  <a
                      class="text-body fw-semibold pb-2 d-block"
                      data-toggle="collapse"
                      href="javascript: void(0);"
                      role="button"
                      aria-expanded="false"
                      v-b-toggle.categories-collapse
                  >
                    <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                  </a>
                  <b-collapse visible id="categories-collapse">
                    <div class="card p-2 border shadow-none">
                        <div class="row">
                          <div class="col">
                            <b-form-group label="First name" label-for="formrow-firstname-input" class="mb-3">
                              <b-form-input type="text" name="firstname" id="formrow-firstname-input" v-model="filterInput.first_name"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col">
                            <b-form-group label="Last name" label-for="formrow-lastname-input" class="mb-3">
                              <b-form-input type="text" name="lastname" id="formrow-lastname-input" v-model="filterInput.last_name"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col">
                            <b-form-group label="Email" label-for="formrow-email-input" class="mb-3">
                              <b-form-input type="text" name="email" id="formrow-email-input" v-model="filterInput.email"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col form-check">
                            <b-form-group label="Gender" class="mb-3">
                              <b-form-checkbox-group name="gender" :options="genderOptions" v-model="filterInput.gender"></b-form-checkbox-group>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group mb-3">
                              <b-form-group label="Birthdate" label-for="formrow-birthdate-input" class="mb-3">
                              <date-picker
                                      v-model="filterInput.birthdate"
                                      range
                                      append-to-body
                                      lang="en"
                                      confirm
                                      format="DD/MM/YYYY"
                              ></date-picker>
                              </b-form-group>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group mb-3">
                              <b-form-group label="User Status" label-for="formrow-userstatus-input" class="mb-3">
                                <multiselect
                                        v-model="filterInput.userStatus"
                                        :options="userStatuses"
                                        :multiple="true"
                                ></multiselect>
                              </b-form-group>
                            </div>
                          </div>
                          <div class="col">
                          </div>
                          <div class="col">
                          </div>
                        </div>
                      <div class="row">
                        <div class="col">
                          <button type="button" class="btn btn-success me-2 w-lg" @click="getCustomers">Filter</button>
                          <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                        </div>
                      </div>

                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <h4 class="card-title">Total Users: {{rows}}</h4>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :busy="isBusy"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                empty-text="No Data Found"
              >

              <template v-slot:cell(first_name)="data">
                  <div  class="avatar-xs d-inline-block me-2">
                      <span class="avatar-title rounded-circle bg-light text-body" v-if="data.item.first_name">{{data.item.first_name.charAt(0)}}</span>
                  </div>
                  <span v-if="data.item.first_name">{{ data.item.first_name + ' ' + data.item.middle_name + ' ' +  data.item.last_name }}</span>
              </template>

              <template v-slot:cell(email)="data">
                <router-link :to="{ path: `/customers/profile/${data.item.id}`}">
                  {{ data.item.email }}
                </router-link>
              </template>

              <template v-slot:cell(status)="data">
                  <div class="badge bg-pill bg-soft-success font-size-12" :class="{'bg-soft-danger': data.item.status !== 'active'}">{{ data.item.status }}</div>
              </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
            
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- MODALS -->
    <modalAddCustomer @onRefresh="getCustomers()"></modalAddCustomer>
    <!-- /MODALS -->

  </Layout>
</template>
