
import Http from './Http'

export default {
    async getCustomer(customer_id) {
        return await Http.get(`customers/${customer_id}`)
    },
    async getCustomers(data) {
        return await Http.get(`customers?${data}`)
    },
    async addCustomer(data) {
        return await Http.post('customers', data)
    },
    async updatePhone(phone_id, data) {
        return await Http.patch(`/customers/phone/${phone_id}`, data)
    },
    async updateEmail(email_id, data) {
        return await Http.patch(`/customers/email/${email_id}`, data)
    },
    async updateAddress(address_id, data) {
        return await Http.patch(`/customers/address/${address_id}`, data)
    },
    async addAddress(data) {
        return await Http.post(`/customers/address`, data)
    },
    async addPhone(data) {
        return await Http.post(`/customers/phone`, data)
    },
    async removeAddress(address_id) {
        return await Http.delete(`/customers/address/${address_id}`)
    },
    async removePhone(phone_id) {
        return await Http.delete(`/customers/phone/${phone_id}`)
    },
    async getUserStatuses() {
        return await Http.get(`/customers/statuses`)
    },
}


